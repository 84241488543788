(function ($) {
  $(function () {
    //logo slider
    $(".autoslide").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "100px",
          },
        },
      ],
    });

    //hero
    $(".hero-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            centerMode: true,
            centerPadding: "30px",
          },
        },
      ],
    });
  });
})(jQuery);
